import React from 'react'
import '../StyleComponents/WhyWhooskaComponents.css'
import horse from '../AssetsComponents/horseRacing.png'
import profile from '../AssetsComponents/imageP.png'
import box from '../AssetsComponents/GroupBox.png'
import image24 from '../AssetsComponents/IMG_48.png'
import image25 from '../AssetsComponents/youtube.png'
import image26 from '../AssetsComponents/twitter.png'
import HeaderComponent from '../ReusableComponent/HeaderComponent'
import logo from '../AssetsComponents/logoMain.png'






function WhyWhooskaComponents() {
    const features = [
        {
            title: "Expert Insights",
            description: "Our dedicated team of racing analysts dives deep into the form, conditions, statistics, sectional speeds, patterns and maps that offer you well-researched tips that can elevate your betting game.",
            icon: "👓"
        },
        {
            title: "Educational Approach",
            description: "We believe in equipping our users with the tools and knowledge they need to become better punters. Learn from our detailed analyses, and improve your skills over time.",
            icon: "📊"
        },
        {
            title: "Long Term Profitability",
            description: "Real success comes from a calculated, long-term strategy. We help you identify winning patterns and trends, leading to more consistent wins and higher profits.",
            icon: "📈"
        },
        {
            title: "Community Support",
            description: "Join a community of like-minded racing enthusiasts where you can share experiences, strategies, and celebrate victories together. You will love the discord group!",
            icon: "👥"
        },
    ];

    return (
        <>
            <HeaderComponent />
            <div className="image-containers" style={{ position: 'relative', width: '100%', height: '500px' }}>
                <img
                    src={horse}
                    alt="Banner"
                    style={{ height: '100%', width: '100%', objectFit: 'cover', filter: 'brightness(50%)' }}
                />
                <div
                    className="text-overlays11"

                >
                    <p>Unleash Your Winning Potential with Whooska Racing Tips!</p>
                </div>
            </div>
            <div style={{ maxWidth: '1150px', margin: '0 auto', padding: '20px', textAlign: 'center' }}>
                <h2 style={{ fontSize: '32px', fontWeight: 'bold', marginBottom: '20px' }}>About Us</h2>
                <p style={{ fontSize: '18px', lineHeight: '1.5', marginBottom: '40px', textAlign: 'left' }}>
                    At Whooska, we understand that successful betting isn't a matter of chance—it's about strategy,
                    knowledge, and discipline. Our mission is to ensure that each punter who utilises our services
                    receives unparalleled value for money and clarity on the reasoning behind our horse selections.
                </p>
                <div className="profile-container">
                    {/* Profile Image */}
                    <div className="profile-image-wrapper">
                        <img
                            src={profile}
                            alt="John Doe"
                            className="profile-image"
                        />
                        <div className="profile-text">
                            <h4 className="profile-name">John Doe</h4>
                            <p className="profile-title">Founder</p>
                        </div>
                    </div>
                    {/* Text Content */}
                    <div className="text-content">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </p>
                    </div>
                </div>

            </div>

            <div className="why-choose-section">
                <h2>Why Choose Whooska Racing Tips?</h2>
                <div className="features-grid">
                    {features.map((feature, index) => (
                        <div key={index} className="feature-card">
                            <div className="icon">{feature.icon}</div>
                            <h3>{feature.title}</h3>
                            <p>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="join-us-container">
                <h2 className="join-us-heading">Join Us</h2>
                <p className="join-us-description">
                    Don't miss out on the opportunity to transform your horse racing betting. With Whooska,
                    you'll not only enhance your chances of winning but also cultivate a deeper understanding
                    of the sport. Say goodbye to guesswork and hello to informed betting!
                </p>
                <button className="join-us-button">Get Your Winning Tips Now!</button>
            </div>

            <div className="get-started-sections" style={{ marginTop: '50px', position: 'relative' }}>
                {/* <div className="container">
                    <div className="content">
                        <h1>Get Started Today</h1>
                        <p>Elevate your horse racing experience with the best tips and insights in the industry. Sign up now and start making smarter betting decisions.</p>
                        <div className="buttons">
                            <button className="sign-up">Sign Up Now</button>
                            <button className="latest-tips">View Latest Tips</button>
                        </div>
                    </div>
                    <img src={image24} alt="Whooska Logo" className="HorseRace" />
                </div> */}

                <div className="footers">
                    <img src={logo} alt="Whooska Logo" className="logos4" />

                    <div className="footer-link">
                        <a href="#">Home</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Tips</a>
                        <div className="vertical-lines"></div>
                        <a href="/WhyWhooskaComponents">About Us</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Contact Us</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Privacy Policy</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Terms & Conditions</a>
                    </div>

                </div>
                <div className="footer-containers1">
                    <div className="left-sides">
                        <p style={{ color: '#FAD45C' }}>copyright @ whooska</p>
                    </div>
                    <p className="centered-paragraphs">This page is for 18+ only. Gamble Responsibly. Visit <a href="#" style={{ color: '#FAD45C', textDecoration: 'none' }}>www.gamblinghelponline.org.vic.au</a> or call Gambling Help on <span style={{ color: '#FAD45C' }}>1800 858 858</span>. The chances are you are about to lose.</p>
                    <div className="right-sides4">
                        <img src={image25} alt="Logo 1" className="logs9" style={{ width: '40px', marginLeft: '10px' }} />
                        <img src={image26} alt="Logo 2" className="logs9" style={{ width: '40px', marginLeft: '10px' }} />
                    </div>
                </div>

            </div>
        </>

    )
}

export default WhyWhooskaComponents