import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../StyleComponents/WhooskaSubscription.css';
import HeaderComponent from '../ReusableComponent/HeaderComponent';
import check from '../AssetsComponents/check.png';
import { FaExclamationCircle } from 'react-icons/fa';
import horse from '../AssetsComponents/horseRacing.png';
import logo from '../AssetsComponents/logoMain.png';
import image25 from '../AssetsComponents/youtube.png';
import image26 from '../AssetsComponents/twitter.png';

function App() {
    const [checkedStates, setCheckedStates] = useState({
        melbourne: false,
        sydney: false,
        brisbane: false,
    });
    
    const [loggedIn, setLoggedIn] = useState(false); // Add a loggedIn state
    const navigate = useNavigate();

    const handleCheckboxChange = (card) => {
        setCheckedStates((prevState) => ({
            ...prevState,
            [card]: !prevState[card],
        }));
    };

    const handleViewCart = () => {
        if (!loggedIn) {
            alert("Please log in to view the cart."); // Alert if user is not logged in
            navigate('/LoginComponents'); // Navigate to the login page
            return;
        }
        
        // Check if at least one subscription is selected
        if (checkedStates.melbourne || checkedStates.sydney || checkedStates.brisbane) {
            navigate('/SubscriptionsCartFirst'); // Navigate to the cart route
        } else {
            alert("Please select at least one subscription before viewing the cart.");
        }
    };

    return (
        <>
            <HeaderComponent />
            <div className="image-containers" style={{ position: 'relative', width: '100%', height: '500px' }}>
                <img
                    src={horse}
                    alt="Banner"
                    style={{ height: '60%', width: '100%', objectFit: 'cover', filter: 'brightness(50%)' }}
                />
                <div
                    className="text-overlays"
                    style={{
                        position: 'absolute',
                        top: '30%',
                        left: '67%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        fontSize: '34px',
                        fontWeight: 'bold',
                        zIndex: '99999',
                        width: '40%',
                    }}
                >
                    <p>Subscription Plans</p>
                </div>
            </div>
            <div className="containerw">
                {/* Subscription Cards Section */}
                <div className="subscription-cards">
                    {/* Melbourne Tips Card */}
                    <div className="cardw">
                        <div className="card-headerw">
                            <h3>Melbourne Tips</h3>
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={checkedStates.melbourne}
                                onChange={() => handleCheckboxChange('melbourne')}
                            />
                        </div>
                        <div className="price-row">
                            <h4>$20 <span>/month</span></h4>
                            <span className="highlight">Saturday Tips</span>
                        </div>
                        <ul>
                            <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
                                <span>
                                    Tips for every race at your favourite metro tracks. The Full Whooska Set!!
                                </span>
                            </li>
                            <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
                                <span>Recommended Unit Bet/Strategy.</span>
                            </li>
                            <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
                                <span>
                                    Tips received by 11am Saturday morning via email.
                                </span>
                            </li>
                        </ul>
                        <button>Add to cart</button>
                    </div>

                    {/* Sydney Tips Card */}
                    <div className="cardw">
                        <div className="card-headerw">
                            <h3>Sydney Tips</h3>
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={checkedStates.sydney}
                                onChange={() => handleCheckboxChange('sydney')}
                            />
                        </div>
                        <div className="price-row">
                            <h4>$20 <span>/month</span></h4>
                            <span className="highlight">Saturday Tips</span>
                        </div>
                        <ul>
                            <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
                                <span>
                                    Tips for every race at your favourite metro tracks. The Full Whooska Set!!
                                </span>
                            </li>
                            <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
                                <span>Recommended Unit Bet/Strategy.</span>
                            </li>
                            <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
                                <span>
                                    Tips received by 11am Saturday morning via email.
                                </span>
                            </li>
                        </ul>
                        <button>Add to cart</button>
                    </div>

                    {/* Brisbane Tips Card */}
                    <div className="cardw">
                        <div className="card-headerw">
                            <h3>Brisbane Tips</h3>
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={checkedStates.brisbane}
                                onChange={() => handleCheckboxChange('brisbane')}
                            />
                        </div>
                        <div className="price-row">
                            <h4>$20 <span>/month</span></h4>
                            <span className="highlight">Saturday Tips</span>
                        </div>
                        <ul>
                            <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
                                <span>
                                    Tips for every race at your favourite metro tracks. The Full Whooska Set!!
                                </span>
                            </li>
                            <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
                                <span>Recommended Unit Bet/Strategy.</span>
                            </li>
                            <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
                                <span>
                                    Tips received by 11am Saturday morning via email.
                                </span>
                            </li>
                        </ul>
                        <button>Add to cart</button>
                    </div>

                    {/* Whooska Ultimate Package Card */}
                    <div className="cardw1 premium-cardw1">
                        <div className="card-headerw1">
                            <h3>Whooska Ultimate Package</h3>
                        </div>
                        <div className="price-row">
                            <h4>$20 <span>/month</span></h4>
                        </div>
                        <ul>
                            <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
                                <span>
                                    <span style={{ color: "black", fontWeight: 'bold' }}>Purchase Tips</span> - Melbourne, Sydney, Brisbane.
                                </span>
                            </li>
                            <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
                                <span>Daily best bets (7 days a week) - Where Whooska's are available.</span>
                            </li>
                            <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
                                <span>Quadie selections for Saturday tracks.</span>
                            </li>
                            <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <img src={check} alt='icon' style={{ height: '13px', marginRight: '10px' }} />
                                <span>Access to Discord group chat to be part of our community.</span>
                            </li>
                        </ul>
                        <button>Add to cart</button>
                    </div>
                </div>
            </div>

            <div className="note-container">
                <div className="note-header">
                    <FaExclamationCircle className="note-icon" />
                    <span className="note-title">Please note</span>
                </div>
                <ul className="note-list">
                    <li>Tips will be emailed to your registered address and also shared on the discord group chat.</li>
                    <li>Monthly subscriptions will automatically be processed each month unless cancelled.</li>
                    <li>
                        If you cancel your monthly membership during the current month, you will not be refunded your monthly
                        subscription. However, your subscription will be cancelled from future transactions.
                    </li>
                </ul>
            </div>

            <div className="view-cart">
                <button onClick={handleViewCart}>View Cart</button>
            </div>

            <div className="get-started-sections" style={{marginTop:'50px',position:"relative"}}>
                {/* <div className="container">
                    <div className="content">
                        <h1>Get Started Today</h1>
                        <p>Elevate your horse racing experience with the best tips and insights in the industry. Sign up now and start making smarter betting decisions.</p>
                        <div className="buttons">
                            <button className="sign-up">Sign Up Now</button>
                            <button className="latest-tips">View Latest Tips</button>
                        </div>
                    </div>
                    <img src={image24} alt="Whooska Logo" className="HorseRace" />
                </div> */}

                <div className="footers">
                    <img src={logo} alt="Whooska Logo" className="logos4" />

                    <div className="footer-link">
                        <a href="#">Home</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Tips</a>
                        <div className="vertical-lines"></div>
                        <a href="/WhyWhooskaComponents">About Us</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Contact Us</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Privacy Policy</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Terms & Conditions</a>
                    </div>

                </div>
                <div className="footer-containers1">
                    <div className="left-sides">
                        <p style={{ color: '#FAD45C',position:'relative' }}>copyright @ whooska</p>
                    </div>
                    <p className="centered-paragraphs">This page is for 18+ only. Gamble Responsibly. Visit <a href="#" style={{ color: '#FAD45C', textDecoration: 'none',position:'relative' }}>www.gamblinghelponline.org.vic.au</a> or call Gambling Help on <span style={{ color: '#FAD45C' }}>1800 858 858</span>. The chances are you are about to lose.</p>
                    <div className="right-sides4">
                        <img src={image25} alt="Logo 1" className="logs9" style={{ width: '40px', marginLeft: '10px' ,position:'relative'}} />
                        <img src={image26} alt="Logo 2" className="logs9" style={{ width: '40px', marginLeft: '10px',position:'relative' }} />
                    </div>
                </div>

            </div>
        </>
    );
}

export default App;
