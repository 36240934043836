import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../AssetsComponents/logoMain.png';
import '../StyleComponents/HeaderComponentMain.css';
import profile from '../AssetsComponents/profile.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import error from '../AssetsComponents/error.png';

function HeaderComponent() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [activeItem, setActiveItem] = useState('');
    const [activeDropdownItem, setActiveDropdownItem] = useState(''); // State for active dropdown item
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLinkClick = (item, path) => {
        setActiveItem(item);
        navigate(path);
    };

    const handleDropdownItemClick = (item) => {
        if (item === 'Log Out') {
            setModalOpen(true);
        } else {
            setActiveDropdownItem(item); // Set active dropdown item
            setDropdownOpen(false);
            if (item === 'Profile') {
                navigate('/ProfileForm');
            } else if (item === 'Purchase History') {
                navigate('/PurchaseHistory');
            }
        }
    };

    const confirmLogout = () => {
        console.log('Logging out...');
        navigate('/LoginComponents');
    };

    const cancelLogout = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownOpen && !event.target.closest('.auth-links11')) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);

    return (
        <div className="header-containers11">
            <header className="headers11">
                <div className="logos11">
                    <Link to="/LoginComponents">
                        <img src={logo} alt="Logos11" style={styles.logoImage} />
                    </Link>
                </div>
                <div className="nav-containers11" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <nav className="navs11">
                        <ul style={{ display: 'flex', listStyle: 'none', padding: '0', margin: '0' }}>
                            <li>
                                <Link
                                    to="/"
                                    onClick={() => handleLinkClick('Home', '/')}
                                    style={{ color: activeItem === 'Home' ? '#F7812A' : 'black' }}
                                >
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/WhyWhooskaComponents"
                                    onClick={() => handleLinkClick('Why Whooska', '/WhyWhooskaComponents')}
                                    style={{ color: activeItem === 'Why Whooska' ? '#F7812A' : 'black' }}
                                >
                                    Why Whooska
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/WhooskaResultsComponents"
                                    onClick={() => handleLinkClick('Results', '/WhooskaResultsComponents')}
                                    style={{ color: activeItem === 'Results' ? '#F7812A' : 'black' }}
                                >
                                    Results
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/WhooskaFreeTipsComponents"
                                    onClick={() => handleLinkClick('Free Tips', '/WhooskaFreeTipsComponents')}
                                    style={{ color: activeItem === 'Free Tips' ? '#F7812A' : 'black' }}
                                >
                                    Free Tips
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/WhooskaSubscription"
                                    onClick={() => handleLinkClick('Purchase Tips', '/WhooskaSubscription')}
                                    style={{ color: activeItem === 'Purchase Tips' ? '#F7812A' : 'black' }}
                                >
                                    Purchase Tips
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/YourTipsComponents"
                                    onClick={() => handleLinkClick('Your Tips', '/YourTipsComponents')}
                                    style={{ color: activeItem === 'Your Tips' ? '#F7812A' : 'black' }}
                                >
                                    Your Tips
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/ContactForm"
                                    onClick={() => handleLinkClick('Contact', '/ContactForm')}
                                    style={{ color: activeItem === 'Contact' ? '#F7812A' : 'black' }}
                                >
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <div className="auth-links11" style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: '15px' }}>
                        <div
                            className="profile"
                            onClick={toggleDropdown}
                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            aria-expanded={dropdownOpen}
                        >
                            <img src={profile} alt="Profile" className="profile-image" style={{ borderRadius: '50%', width: '40px', height: '40px' }} />
                            <span className="profile-name" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                                John Deo
                                <FontAwesomeIcon icon={faCaretDown} style={{ marginLeft: '5px', color: '#000' }} />
                            </span>
                        </div>

                        {dropdownOpen && (
                            <div style={styles.dropdown}>
                                {['Profile', 'Purchase History', 'Log Out'].map((item) => (
                                    <div
                                        key={item}
                                        style={activeDropdownItem === item ? styles.optionActive : styles.option}
                                        onClick={() => handleDropdownItemClick(item)}
                                    >
                                        {item}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </header>

            {/* Modal for Logout Confirmation */}
            {modalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <img src={error} alt="Error" className="modal-icon" />
                        </div>
                        <div className="modal-body">
                            <h2>Log Out</h2>
                            <p>Are you sure you want to Log Out?</p>
                        </div>
                        <div className="modal-footer">
                            <button onClick={confirmLogout} className="deactivate-btn">Logout</button>
                            <button onClick={cancelLogout} className="cancel-btn">Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const styles = {
    dropdown: {
        width: '200px',
        borderRadius: '10px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        backgroundColor: '#fff',
        padding: '10px',
        fontFamily: 'Arial, sans-serif',
        position: 'absolute',
        marginLeft: '-100px',
        marginBottom: '-200px',
        zIndex: 1000,
    },
    option: {
        padding: '10px',
        marginBottom: '5px',
        cursor: 'pointer',
        borderRadius: '5px',
        color: '#000',
    },
    optionActive: {
        padding: '10px',
        marginBottom: '5px',
        cursor: 'pointer',
        borderRadius: '5px',
        backgroundColor: '#333',
        color: '#fff',
    },
};

export default HeaderComponent;
