import React, { useState } from 'react';
import '../StyleComponents/ProfileForm.css'
import image50 from '../AssetsComponents/image50.png'
import HeaderComponent from '../ReusableComponent/HeaderComponentMain';
import image25 from '../AssetsComponents/youtube.png';
import image26 from '../AssetsComponents/twitter.png';
import logo from '../AssetsComponents/logoMain.png';

const ProfileForm = () => {
    const [formData, setFormData] = useState({
        name: 'John Doe',
        email: 'user.whooska@gmail.com',
        mobilePrefix: '+61',
        mobileNumber: '900 0000 000',
        address: '1549 Glory Road, Aurora, Colorado, United States',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <>
            <HeaderComponent />

            <div className="profile-form-container">
                <div className="profile-header">
                    <img
                        src={image50} // replace with actual image URL
                        alt="User Profile"
                        className="profile-picture"
                    />
                    <span className="edit-icon">✏️</span>
                </div>

                <form className="profile-form">
                    <div className="form-group33">
                        <label style={{ color: 'grey', fontSize: 12 }}>Name</label>
                        <input
                            type="text"
                            name="name"
                            // value={formData.name}
                            onChange={handleChange}
                            className="input-field33"
                            placeholder='Name'
                        />
                    </div>

                    <div className="form-group33">
                        <label style={{ color: 'grey', fontSize: 12 }}>Email</label>
                        <input
                            type="email"
                            name="email"
                            // value={formData.email}
                            onChange={handleChange}
                            className="input-field33"
                            placeholder='Email'

                        />
                    </div>

                    <div className="form-group33">
                        <label style={{ color: 'grey', fontSize: 12 }}>Mobile Number</label>
                        <div className="mobile-number-container33">
                            <input
                                type="text"
                                name="mobilePrefix"
                                // value={formData.mobilePrefix}
                                onChange={handleChange}
                                className="input-field33 mobile-prefix"
                                placeholder='+61'

                            />
                            <input
                                type="text"
                                name="mobileNumber"
                                // value={formData.mobileNumber}
                                onChange={handleChange}
                                className="input-field33 mobile-number33"
                                placeholder='Mobile'

                            />
                        </div>
                    </div>

                    <div className="form-group33">
                        <label style={{ color: 'grey', fontSize: 12 }}>Address</label>
                        <textarea
                            name="address"
                            // value={formData.address}
                            onChange={handleChange}
                            className="input-field33"
                            placeholder='Address'

                        />
                    </div>

                    <button type="button" className="edit-profile-button">
                        Edit Profile
                    </button>
                </form>
            </div>
            <div className="get-started-sections">
                <div className="footers">
                    <img src={logo} alt="Whooska Logo" className="logos4" />
                    <div className="footer-link">
                        <a href="#">Home</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Tips</a>
                        <div className="vertical-lines"></div>
                        <a href="/WhyWhooskaComponents">About Us</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Contact Us</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Privacy Policy</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Terms & Conditions</a>
                    </div>
                </div>
                <div className="footer-containers1">
                    <div className="left-sides">
                        <p style={{ color: '#FAD45C' }}>copyright @ whooska</p>
                    </div>
                    <p className="centered-paragraphs">This page is for 18+ only. Gamble Responsibly. Visit <a href="#" style={{ color: '#FAD45C', textDecoration: 'none' }}>www.gamblinghelponline.org.vic.au</a> or call Gambling Help on <span style={{ color: '#FAD45C' }}>1800 858 858</span>. The chances are you are about to lose.</p>
                    <div className="right-sides4">
                        <img src={image25} alt="Logo 1" className="logs9" style={{ width: '40px', marginLeft: '10px' }} />
                        <img src={image26} alt="Logo 2" className="logs9" style={{ width: '40px', marginLeft: '10px' }} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileForm;
