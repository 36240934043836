import React, { useState } from 'react';
import HeaderComponent from '../ReusableComponent/HeaderComponentMain';
import image18 from '../AssetsComponents/Vector.png'
import { FaMapMarkerAlt } from 'react-icons/fa';
import horse from '../AssetsComponents/horseRacing.png'
import image25 from '../AssetsComponents/youtube.png';
import image26 from '../AssetsComponents/twitter.png';
import logo from '../AssetsComponents/logoMain.png';
import BestBets from './BestBets';
import SydneyTips from './SydneyTips';
import BrisbaneSetion from './BrisbaneSection'





const Tip = ({ number, track, raceNo, horseName, bettingUnit, analysis, location }) => {
    return (
        <div className="tip-container">
            <div className="tip-header">
                <h3>Free Tips #{number}</h3>
                <div className="location">
                    {/* <span role="img" aria-label="location">
                        <FaMapMarkerAlt style={{ color: 'orange', fontSize: '15px' }} />
                    </span> */}
                    {/* {location} */}
                </div>
            </div>

            {/* Table for displaying details */}
            <hr className="divider" /> {/* Horizontal line */}

            <table className="tip-table">
                <thead>
                    <tr>
                        <th>Track</th>
                        <th>Race No</th>
                        <th>Horse Name</th>
                        <th>Recommended Betting Unit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <img src={image18} alt="Track" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                            {track}</td>
                        <td>{raceNo}</td>
                        <td>{horseName}</td>
                        <td>{bettingUnit}</td>
                    </tr>
                </tbody>
            </table>

            <div className="horse-analysis-container">
                <hr className="divider" /> {/* Horizontal line */}
                <div className="horse-analysis">
                    <strong style={{ color: '#ff9500' }}>Horse Analysis</strong>
                    <p>{analysis}</p>
                </div>
            </div>
        </div>
    );
};






function YourTipsComponents() {
    const [activeButton, setActiveButton] = useState('Melbourne');

    const handleClick = (section) => {
        setActiveButton(section);
    };

    return (
        <>
            <HeaderComponent />
            <div>
                {/* Buttons */}
                <div style={styles.buttonContainer}>
                    <button
                        style={activeButton === 'Melbourne' ? styles.activeButton : styles.button}
                        onClick={() => handleClick('Melbourne')}
                    >
                        Melbourne
                    </button>
                    <button
                        style={activeButton === 'Sydney' ? styles.activeButton : styles.button}
                        onClick={() => handleClick('Sydney')}
                    >
                        Sydney
                    </button>
                    <button
                        style={activeButton === 'Brisbane' ? styles.activeButton : styles.button}
                        onClick={() => handleClick('Brisbane')}
                    >
                        Brisbane
                    </button>
                    <button
                        style={activeButton === 'Best Bets' ? styles.activeButton : styles.button}
                        onClick={() => handleClick('Best Bets')}
                    >
                        Best Bets
                    </button>
                </div>

                {/* Only render the selected section */}
                <div style={styles.sectionContainer}>
                    {activeButton === 'Melbourne' && (
                        <div style={styles.section}>
                            <h2>Melbourne Section</h2>
                            <SydneyTips/>
                            </div>
                    )}
                    {activeButton === 'Sydney' && (
                        <div style={styles.section}>
                            <div className="image-containers" style={{ position: 'relative', width: '100%', marginTop:'10%'}}>
                                
                              
                            </div>
                            <div className="App">
                            <h1 style={styles.title}>Sydney Tips</h1>

                                <div className="tips-expiration">
                                    <p>Tips to expire Saturday at 10pm, Australian Eastern Standard Time (AEST)</p>
                                </div>

                                <Tip
                                    number={1}
                                    track="BM78"
                                    raceNo="01"
                                    horseName="Forecaster"
                                    bettingUnit="$7 - 0.5u"
                                    analysis="Looks the one to beat for me. Lost last start by 0.9L over 1500m. Before that lost by 0.7L to The Extreme Cat which is flying over 1400m. Step up to 1800m here, but has won at 1600m. Good price to find out if it can run out a strong 1800m. If it does, it should win."
                                    location="Melbourne"
                                    
                                />
                                <Tip
                                    number={2}
                                    track="BM78"
                                    raceNo="02"
                                    horseName="Sequestered"
                                    bettingUnit="$3.1 - 1.5u"
                                    analysis="Was a painful watch last start, got clear very late and would have won if it did sooner. Ran the 2nd fastest 400-200m of the day and 4th fastest last furlong of the day with 11.34s. I am sticking fat here."
                                    location="Sydney"
                                />
                                <Tip
                                    number={3}
                                    track="BM78"
                                    raceNo="03"
                                    horseName="Zarrose"
                                    bettingUnit="$6 - 0.5u"
                                    analysis="This horse won over 1400m soft5 last start in slick time from 7th in the field. Ran the 2nd fastest 4-200m of the day and the fastest last 200m of the day in 11.16s. That is some serious speed."
                                    location="Brisbane"
                                />
                            </div>
                        </div>
                    )}
                    {activeButton === 'Brisbane' && (
                        <div style={styles.section}>
                            <h2>Brisbane Section</h2>
                           <BrisbaneSetion/>
                        </div>
                    )}
                    {activeButton === 'Best Bets' && (
                       <BestBets/>
                    )}
                </div>
            </div>
            <div className="get-started-sections">
                {/* <div className="container">
                    <div className="content">
                        <h1>Get Started Today</h1>
                        <p>Elevate your horse racing experience with the best tips and insights in the industry. Sign up now and start making smarter betting decisions.</p>
                        <div className="buttons">
                            <button className="sign-up">Sign Up Now</button>
                            <button className="latest-tips">View Latest Tips</button>
                        </div>
                    </div>
                    <img src={image24} alt="Whooska Logo" className="HorseRace" />
                </div> */}

                <div className="footers">
                    <img src={logo} alt="Whooska Logo" className="logos4" />

                    <div className="footer-link">
                        <a href="#">Home</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Tips</a>
                        <div className="vertical-lines"></div>
                        <a href="/WhyWhooskaComponents">About Us</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Contact Us</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Privacy Policy</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Terms & Conditions</a>
                    </div>

                </div>
                <div className="footer-containers1">
                    <div className="left-sides">
                        <p style={{ color: '#FAD45C' }}>copyright @ whooska</p>
                    </div>
                    <p className="centered-paragraphs">This page is for 18+ only. Gamble Responsibly. Visit <a href="#" style={{ color: '#FAD45C', textDecoration: 'none' }}>www.gamblinghelponline.org.vic.au</a> or call Gambling Help on <span style={{ color: '#FAD45C' }}>1800 858 858</span>. The chances are you are about to lose.</p>
                    <div className="right-sides4">
                        <img src={image25} alt="Logo 1" className="logs9" style={{ width: '40px', marginLeft: '10px' }} />
                        <img src={image26} alt="Logo 2" className="logs9" style={{ width: '40px', marginLeft: '10px' }} />
                    </div>
                </div>

            </div>
        </>
    );
}

const styles = {
    buttonContainer: {
        display: 'flex',
        margin: '20px',
    },
    title: {
        fontSize: '28px',
        fontWeight: 'bold',
        marginBottom: '10px',
        marginTop:'40px'
      },
    button: {
        padding: '10px 20px',
        margin: '0 10px',
        border: '2px solid #F7812A',
        borderRadius: '10px',
        backgroundColor: 'white',
        color: '#F7812A',
        cursor: 'pointer',
    },
    activeButton: {
        padding: '10px 20px',
        margin: '0 10px',
        border: '2px solid orange',
        borderRadius: '10px',
        backgroundColor: '#F7812A',
        color: 'white',
        cursor: 'pointer',
    },
    sectionContainer: {
        marginTop: '20px',
        display: 'flex',
        // justifyContent: 'center',
    },
    section: {
        padding: '20px',
        // border: '1px solid #ccc',
        width: '100%',
        // textAlign: 'center',
    },
};

export default YourTipsComponents;
