import React, { useState } from 'react';
import '../StyleComponents/PurchaseHistory.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import HeaderComponent from '../ReusableComponent/HeaderComponentMain';
import image25 from '../AssetsComponents/youtube.png';
import image26 from '../AssetsComponents/twitter.png';
import logo from '../AssetsComponents/logoMain.png';
import error from '../AssetsComponents/error.png'

const PurchaseHistory = () => {
    const initialData = [
        { id: 1, plan: 'Monthly', amount: '$10', date: '10 Aug 2024', expire: '10 Sep 2024', status: 'Active' },
        { id: 2, plan: 'Melbourne', amount: '$10', date: '09 Aug 2024', expire: '09 Sep 2024', status: 'Expired' },
        { id: 3, plan: 'Brisbane', amount: '$10', date: '12 Jul 2024', expire: '12 Aug 2024', status: 'Expired' },
        { id: 4, plan: 'Sydney', amount: '$10', date: '10 Jun 2024', expire: '10 Jul 2024', status: 'Expired' },
        { id: 1, plan: 'Monthly', amount: '$10', date: '10 Aug 2024', expire: '10 Sep 2024', status: 'Active' },
        { id: 2, plan: 'Melbourne', amount: '$10', date: '09 Aug 2024', expire: '09 Sep 2024', status: 'Expired' },
        { id: 3, plan: 'Brisbane', amount: '$10', date: '12 Jul 2024', expire: '12 Aug 2024', status: 'Expired' },
        { id: 4, plan: 'Sydney', amount: '$10', date: '10 Jun 2024', expire: '10 Jul 2024', status: 'Expired' },
        { id: 1, plan: 'Monthly', amount: '$10', date: '10 Aug 2024', expire: '10 Sep 2024', status: 'Active' },
        { id: 2, plan: 'Melbourne', amount: '$10', date: '09 Aug 2024', expire: '09 Sep 2024', status: 'Expired' },
        { id: 3, plan: 'Brisbane', amount: '$10', date: '12 Jul 2024', expire: '12 Aug 2024', status: 'Expired' },
        { id: 4, plan: 'Sydney', amount: '$10', date: '10 Jun 2024', expire: '10 Jul 2024', status: 'Expired' },

    ];

    const [data, setData] = useState(initialData);
    const [currentPage, setCurrentPage] = useState(1);
    const [showPopup, setShowPopup] = useState(false);
    const [itemToDeactivate, setItemToDeactivate] = useState(null);

    const itemsPerPage = 4; // Assuming a maximum of 4 items
    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const handleDeactivateClick = (item) => {
        setItemToDeactivate(item);
        setShowPopup(true);
    };

    const confirmDeactivate = () => {
        setData((prevData) =>
            prevData.map((item) =>
                item.id === itemToDeactivate.id ? { ...item, status: 'Expired' } : item
            )
        );
        setShowPopup(false);
        setItemToDeactivate(null); // Resetting the item
    };

    const cancelDeactivate = () => {
        setShowPopup(false);
        setItemToDeactivate(null); // Resetting the item
    };

    return (
        <>
            <HeaderComponent />
            <div className="header-container33">
                <h2 className='Pur'>Purchase History</h2>
                <p className="info-text">
                    All individual purchases will expire every Saturday at 10pm. Australian Eastern Standard Time (AEST)
                </p>
            </div>

            <div className="purchase-history">
                <table className="purchase-table">
                    <thead className="purchase">
                        <tr>
                            <th>S No</th>
                            <th>Subscription Plan</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Expire on</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((item) => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.plan}</td>
                                <td>{item.amount}</td>
                                <td>{item.date}</td>
                                <td>{item.expire}</td>
                                <td>
                                    <span className={`status ${item.status.toLowerCase()}`}>
                                        {item.status === 'Active' ? (
                                            <>
                                                <span className="status-dot green"></span> Active
                                                <span
                                                    className="deactivate"
                                                    onClick={() => handleDeactivateClick(item)}
                                                    style={{ cursor: 'pointer', marginLeft: '25px' }}
                                                >
                                                    Deactivate
                                                </span>
                                                <FontAwesomeIcon icon={faCaretRight} style={{ marginLeft: '5px', color: '#F7812A' }} />
                                            </>
                                        ) : (
                                            <>
                                                <span className="status-dot red"></span>
                                                Expired
                                            </>
                                        )}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="pagination">
                    <p style={{ color: '#888888' }}>
                        Showing {data.length} out of {data.length} entries
                    </p>
                    <div className="pagination-controls">
                        {[...Array(totalPages)].map((_, index) => {
                            const pageNumber = index + 1;
                            return (
                                <React.Fragment key={pageNumber}>
                                    <span
                                        onClick={() => handlePageClick(pageNumber)}
                                        className={currentPage === pageNumber ? 'active-page' : ''}
                                        style={{ cursor: 'pointer', padding: '0 5px' }}
                                    >
                                        {pageNumber}
                                    </span>
                                    {/* Add vertical line except for the last page number */}
                                    {index < totalPages - 1 && (
                                        <span style={{ margin: '0 5px', color: '#888888' }}>|</span>
                                    )}
                                </React.Fragment>
                            );
                        })}
                        <span
                            onClick={() => handlePageClick(currentPage + 1 <= totalPages ? currentPage + 1 : totalPages)}
                            style={{ cursor: 'pointer' }}
                        >
                            Next
                        </span>
                    </div>
                </div>
            </div>

            {showPopup && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            {/* <FontAwesomeIcon icon={faCircleExclamation} className="modal-icon" /> */}
                            <img src={error} alt="Logo 1" className="modal-icon" />

                        </div>
                        <div className="modal-body">
                            <h2 style={{ color: '#F7812A', fontSize: '24px' }}>Deactivate Plan</h2>
                            <p>Are you sure you want to deactivate this plan?</p>
                        </div>
                        <div className="modal-footer">
                            <button className="deactivate-btn" onClick={confirmDeactivate}>
                                Deactivate
                            </button>
                            <button className="cancel-btn" onClick={cancelDeactivate}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className="get-started-sections">
                <div className="footers">
                    <img src={logo} alt="Whooska Logo" className="logos4" />
                    <div className="footer-link">
                        <a href="#">Home</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Tips</a>
                        <div className="vertical-lines"></div>
                        <a href="/WhyWhooskaComponents">About Us</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Contact Us</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Privacy Policy</a>
                        <div className="vertical-lines"></div>
                        <a href="#">Terms & Conditions</a>
                    </div>
                </div>
                <div className="footer-containers1">
                    <div className="left-sides">
                        <p style={{ color: '#FAD45C' }}>copyright @ whooska</p>
                    </div>
                    <p className="centered-paragraphs">This page is for 18+ only. Gamble Responsibly. Visit <a href="#" style={{ color: '#FAD45C', textDecoration: 'none' }}>www.gamblinghelponline.org.vic.au</a> or call Gambling Help on <span style={{ color: '#FAD45C' }}>1800 858 858</span>. The chances are you are about to lose.</p>
                    <div className="right-sides4">
                        <img src={image25} alt="Logo 1" className="logs9" style={{ width: '40px', marginLeft: '10px' }} />
                        <img src={image26} alt="Logo 2" className="logs9" style={{ width: '40px', marginLeft: '10px' }} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PurchaseHistory;
