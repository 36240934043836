import { useState } from 'react';
import '../StyleComponents/LandingPageComponents.css'
import logo from '../AssetsComponents/logoMain.png'
import horse from '../AssetsComponents/horseRacing.png'
import image11 from '../AssetsComponents/image11.png'
import image16 from '../AssetsComponents/image16.png'
import image17 from '../AssetsComponents/Frame20.png'
import image18 from '../AssetsComponents/Vector.png'
import image19 from '../AssetsComponents/Frame21.png'

import image20 from '../AssetsComponents/image111.png'
import image21 from '../AssetsComponents/IMG21.png'
import image22 from '../AssetsComponents/image20.png'
import image23 from '../AssetsComponents/Frame22.png'
import image24 from '../AssetsComponents/IMG_48.png'
import image25 from '../AssetsComponents/youtube.png'
import image26 from '../AssetsComponents/twitter.png'
import gs1 from '../AssetsComponents/gs1.png'
import gsv1 from '../AssetsComponents/gsv1.png'
import gsv2 from '../AssetsComponents/gsv2.png'


import { Link } from 'react-router-dom';




import { IoCheckmarkCircleOutline } from "react-icons/io5";





function LandingPageComponents() {


    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeLink, setActiveLink] = useState('Home');

    const handleLinkClick = (link) => {
        setActiveLink(link);
        setIsMenuOpen(false); // Close the menu when a link is clicked
    };


    const data = [
        {
            date: '10 Aug 2024',
            title: 'Saratoga Power',
            race: '06',
            location: 'Muswellbrook',
            status: 'Win',
            odd: '$6',
            stake: '$3',
            collected: '+$310',
            image: image18, // Ensure this is properly imported or linked
        },
        {
            date: '10 Aug 2024',
            title: 'Saratoga Power',
            race: '06',
            location: 'Muswellbrook',
            status: 'Win',
            odd: '$6',
            stake: '$3',
            collected: '+$310',
            image: image18, // Ensure this is properly imported or linked
        },
        {
            date: '10 Aug 2024',
            title: 'Saratoga Power',
            race: '06',
            location: 'Muswellbrook',
            status: 'Win',
            odd: '$6',
            stake: '$3',
            collected: '+$310',
            image: image18, // Ensure this is properly imported or linked
        },
        {
            date: '10 Aug 2024',
            title: 'Saratoga Power',
            race: '06',
            location: 'Muswellbrook',
            status: 'Win',
            odd: '$6',
            stake: '$3',
            collected: '+$310',
            image: image18, // Ensure this is properly imported or linked
        }, {
            date: '10 Aug 2024',
            title: 'Saratoga Power',
            race: '06',
            location: 'Muswellbrook',
            status: 'Win',
            odd: '$6',
            stake: '$3',
            collected: '+$310',
            image: image18, // Ensure this is properly imported or linked
        }, {
            date: '10 Aug 2024',
            title: 'Saratoga Power',
            race: '06',
            location: 'Muswellbrook',
            status: 'Win',
            odd: '$6',
            stake: '$3',
            collected: '+$310',
            image: image18, // Ensure this is properly imported or linked
        }, {
            date: '10 Aug 2024',
            title: 'Saratoga Power',
            race: '06',
            location: 'Muswellbrook',
            status: 'Win',
            odd: '$6',
            stake: '$3',
            collected: '+$310',
            image: image18, // Ensure this is properly imported or linked
        }, {
            date: '10 Aug 2024',
            title: 'Saratoga Power',
            race: '06',
            location: 'Muswellbrook',
            status: 'Win',
            odd: '$6',
            stake: '$3',
            collected: '+$310',
            image: image18, // Ensure this is properly imported or linked
        }, {
            date: '10 Aug 2024',
            title: 'Saratoga Power',
            race: '06',
            location: 'Muswellbrook',
            status: 'Win',
            odd: '$6',
            stake: '$3',
            collected: '+$310',
            image: image18, // Ensure this is properly imported or linked
        }, {
            date: '10 Aug 2024',
            title: 'Saratoga Power',
            race: '06',
            location: 'Muswellbrook',
            status: 'Win',
            odd: '$6',
            stake: '$3',
            collected: '+$310',
            image: image18, // Ensure this is properly imported or linked
        },
        // Add more data as needed
    ];
    const images = [image22, image21, image20, image22, image21, image20];

    return (
        <>
            <div className="header-container">
                <header className="header">
                    <div className="logo">
                        <Link to="/LoginComponents"> {/* Set the link to navigate to the login page */}
                            <img src={logo} alt="Logos11" />
                        </Link>
                    </div>
                    <div className="nav-container">
                        <nav className="nav" style={{ zIndex: 99999 }}>
                            <div
                                className="hamburger"
                                onClick={() => setIsMenuOpen(prev => !prev)} // Toggle menu
                                style={{ color: isMenuOpen ? 'black' : 'white' }}
                            >
                                {isMenuOpen ? '✖' : '☰'}
                            </div>
                            <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                                <li>
                                    <Link
                                        to="/"
                                        onClick={() => handleLinkClick('Home')}
                                        style={{ color: activeLink === 'Home' ? 'red' : 'white' }}
                                    >
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/WhyWhooskaComponents"
                                        onClick={() => handleLinkClick('Why Whooska')}
                                        style={{ color: activeLink === 'Why Whooska' ? 'red' : 'black' }}
                                    >
                                        Why Whooska
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/WhooskaFreeTipsComponents"
                                        onClick={() => handleLinkClick('Free Tips')}
                                        style={{ color: activeLink === 'Free Tips' ? 'red' : 'black' }}
                                    >
                                        Free Tips
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/WhooskaSubscription"
                                        onClick={() => handleLinkClick('Purchase Tips')}
                                        style={{ color: activeLink === 'Purchase Tips' ? 'red' : 'black' }}
                                    >
                                        Purchase Tips
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/WhooskaResultsComponents"
                                        onClick={() => handleLinkClick('Results')}
                                        style={{ color: activeLink === 'Results' ? 'red' : 'black' }}
                                    >
                                        Results
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/LoginComponents"
                                        onClick={() => handleLinkClick('Log In')}
                                        style={{ color: activeLink === 'Log In' ? 'red' : 'white' }}
                                        className='ab'
                                    >
                                        Log In
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/RegisterComponent"
                                        onClick={() => handleLinkClick('Sign Up')}
                                        style={{ color: activeLink === 'Sign Up' ? 'red' : 'white' }}
                                        className='ab'
                                    >
                                        Sign Up
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </header>
                <div className="image-container">
                    <img
                        src={horse}
                        alt="Banner"
                        style={{ height: '500px', width: '100%' }}
                        className='Imgh'
                    />
                    <div className="text-overlay" style={{
                        display: 'flex',
                        position: 'absolute',
                        top: '30%',
                        left: '70%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        fontSize: '34px',
                        fontWeight: 'bold',
                        zIndex: '99999'
                    }}>
                        <p>Maximize Your Winnings with Expert Insights</p>
                    </div>
                    <div className="text-overlay" style={{
                        display: 'flex',
                        position: 'absolute',
                        top: '53%',
                        left: '70%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        fontSize: '14px',
                        zIndex: '99999',
                        // width: '20%'

                    }}>
                        <p>At Whooska, we bring you the best tips for Melbourne, Sydney, and Brisbane horse races. Our expert insights ensure that you have the knowledge needed to make smarter bets every weekend.</p>
                    </div>
                    <div className="text-overlay" style={{
                        position: 'absolute',
                        top: '75%',
                        left: '10%',
                        color: 'white',
                        fontSize: '14px',
                        zIndex: '99999',


                    }}>
                        <img src={image11} alt='image ' style={{ height: '150px', width: '250px' }} className='ImgHo' />
                    </div>
                    <div className="text-overlay" style={{
                        position: 'absolute',
                        top: '75%',
                        left: '10%',
                        color: 'white',
                        fontSize: '14px',
                        zIndex: '99999',


                    }}>
                        <img src={image16} alt='image ' style={{ height: '150px', width: '70px' }} className='ImgHo1' />
                    </div>
                    <div className="text-overlay" style={{
                        position: 'absolute',
                        top: '75%',
                        left: '10%',
                        color: 'white',
                        fontSize: '14px',
                        zIndex: '99999',
                        marginLeft: '20px'


                    }}>
                        <h2 className='ImgHo3'>lorem ipsum</h2>
                        <h5 style={{ marginTop: '60px', marginLeft: '17px' }} className='ImgHo4'>lorem ipsum</h5>

                    </div>
                    <div className="text-overlay" style={{
                        position: 'absolute',
                        top: '100%',
                        left: '20%',
                        color: 'white',
                        fontSize: '14px',
                        zIndex: '99999',


                    }}>
                        <img src={image17} alt='image ' style={{ height: '50px', width: '50px' }} className='ImgHo2' />
                    </div>
                </div>
                <div style={{ fontFamily: 'Arial, sans-serif', margin: '0 auto', padding: '100px' }}>
                    <h1 style={{ fontSize: '2.5em', marginBottom: '20px', textAlign: 'left' }} className='WhyWhooska'>Why Whooska?</h1>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className='recommend'>
                        <div style={{ flex: '1 1 50%', marginBottom: '20px' }}>
                            <div style={{ marginBottom: '20px' }}>
                                <h2 style={{ color: '#ff6600', fontSize: '1.5em' }}>
                                    <span role="img" aria-label="Expert Tips" style={{ marginRight: '10px' }}>🎫</span>
                                    Expert Tips
                                </h2>
                                <p style={{ width: '80%', marginLeft: '50px' }}>At Whooska, we provide trustworthy and dependable insights to guide your betting decisions. We do not recommend bets that we wouldn't place ourselves.</p>
                            </div>

                            <div>
                                <h2 style={{ color: '#ff6600', fontSize: '1.5em', marginLeft: '50px' }}>Data Analysis</h2>
                                <p style={{ width: '80%', marginLeft: '50px' }}>You cannot have consistent results without in-depth data analysis. At Whooska, we analyze the following factors/criteria in each tip we provide:</p>
                                <ul style={{ paddingLeft: '20px', listStyleType: 'none', marginLeft: '40px' }}>
                                    <li>⏱️ Sectional speeds</li>
                                    <li>⚖️ Weight</li>
                                    <li>🚧 Barrier Draw</li>
                                    <li>🗺️ Map & Horse Pattern</li>
                                    <li>🏟️ Track Condition</li>
                                    <li>🐎 Past horse performance</li>
                                    <li>🏇 Jockey / Trainer</li>
                                    <li>📊 Edges</li>
                                </ul>
                                <p>Let us do the hard work for you!</p>
                            </div>
                        </div>

                        <div style={{ flex: '1 1 50%', marginBottom: '20px' }}>
                            <div style={{ marginBottom: '20px' }}>
                                <h2 style={{ color: '#ff6600', fontSize: '1.5em' }}>
                                    <span role="img" aria-label="Comprehensive Coverage" style={{ marginRight: '10px' }}>📅</span>
                                    Comprehensive Coverage
                                </h2>
                                <p style={{ width: '90%', marginLeft: '50px' }}>From major races to local meets, our tips cover a wide range of events, ensuring you have the insights you need for every race.</p>
                            </div>

                            <div style={{ marginBottom: '20px' }}>
                                <h2 style={{ color: '#ff6600', fontSize: '1.5em' }}>
                                    <span role="img" aria-label="User-Friendly Platform" style={{ marginRight: '10px' }}>👍</span>
                                    User-Friendly Platform
                                </h2>
                                <p style={{ width: '90%', marginLeft: '50px' }}>Our website is designed for ease of use, allowing you to quickly access tips, statistics, and race details from any device.</p>
                            </div>

                            <div style={{ position: 'relative', width: '100%', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {/* Black box behind the images */}
                                <div
                                    style={{
                                        width: '150px',
                                        height: '150px',
                                        backgroundColor: '#FFF2E9',
                                        borderRadius: '50%',
                                        position: 'relative',
                                        marginTop: '80px'
                                    }}
                                ></div>

                                {/* First Image */}
                                <img
                                    src={image11}
                                    alt="Horse race"
                                    style={{
                                        width: '40%',
                                        height: '150px',
                                        objectFit: 'cover',
                                        position: 'absolute',
                                        left: '5%',
                                        top: '25px',
                                        borderRadius: '5px'
                                    }}
                                />

                                {/* Second Image */}
                                <img
                                    src={image11}
                                    alt="Another horse race"
                                    style={{
                                        width: '40%',
                                        height: '150px',
                                        objectFit: 'cover',
                                        position: 'absolute',
                                        right: '5%',
                                        top: '110px',
                                        borderRadius: '5px'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="results-container">
                    <h1 style={{ position: 'relative' }} className='Results'>Results</h1>
                    <div className="results-grid">
                        {data.map((item, index) => (
                            <div key={index} className="card">
                                <div className="card-header">
                                    <p >{item.date}</p>
                                </div>
                                <div className="card-content">
                                    <h3>
                                        <img
                                            src={item.image}
                                            alt={item.title}
                                            style={{ width: '30px', height: '30px', marginRight: '10px' }}
                                        />
                                        {item.title}
                                    </h3>

                                </div>
                                <div className="card-content1">

                                    <p>Race {item.race}</p>
                                </div>
                                <hr style={{ border: '0.1px solid grey', width: '100%', }} />

                                <div className="card-content2">

                                    <p>{item.location}</p>
                                </div>
                                <div className="card-content3">

                                    <p>
                                        {item.status}
                                        <IoCheckmarkCircleOutline
                                            style={{
                                                color: 'white',
                                                backgroundColor: 'green',
                                                borderRadius: '50%',

                                                display: 'inline-flex',  // Ensures the circle is perfectly sized
                                                justifyContent: 'center', // Centers the icon inside the circle
                                                alignItems: 'center',     // Vertically centers the icon
                                                marginLeft: '10px'    // Space between text and icon
                                            }}
                                        />
                                    </p>
                                </div>
                                <hr style={{ border: '0.1px solid grey', width: '100%' }} />

                                <div className="card-footer">
                                    <p>Odd </p>
                                    <p>Stake </p>
                                    <p>Collected </p>
                                </div>
                                <div className="card-footer1">
                                    <span>{item.odd}</span>
                                    <span>{item.stake}</span>
                                    <span className="collected-amount">{item.collected}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="how-it-works-container">
                    <h1 style={{ position: 'relative' }} className='Results'>How It Works</h1>

                    <div className="step">
                        <div className="step-image1">
                            <img src={gs1} alt="Sign Up for Free" />
                        </div>
                        <div className="step-content">
                            <h2 style={{ textAlign: 'left' }} >Sign Up for Free</h2>
                            <p style={{ textAlign: 'left' }}>Create your free account in just a few clicks. Join the Whooska community and gain access to expert horse racing tips tailored to maximize your winnings.</p>
                        </div>

                    </div>

                    <div className="arrow1">
                        <img src={gsv1} alt="Explore Tips" />
                    </div>

                    <div className="step1 reverse1">
                        <div className="step-image">
                            <img src={gs1} alt="Explore Tips" />
                        </div>
                        <div className="step-content1">
                            <h2 style={{ textAlign: 'left' }}>Explore Our Tips</h2>
                            <p style={{ textAlign: 'left' }}>Browse through a variety of tips for Melbourne, Sydney, and Brisbane races. Our tips are designed to give you the edge with detailed insights and expert analysis.</p>
                        </div>
                    </div>
                    {/* 
                    <div className="arrow">
                        <span>&#8595;</span>
                    </div> */}
                    <div className="arrow">
                        <img src={gsv2} alt="Explore Tips" />
                    </div>

                    <div className="step">
                        <div className="step-image1">
                            <img src={gs1} alt="Sign Up for Free" />
                        </div>
                        <div className="step-content">
                            <h2 style={{ textAlign: 'left' }}>Sign Up for Free</h2>
                            <p style={{ textAlign: 'left' }}>Create your free account in just a few clicks. Join the Whooska community and gain access to expert horse racing tips tailored to maximize your winnings.</p>
                        </div>

                    </div>


                    <div className="arrow">
                        <img src={gsv1} alt="Explore Tips" />
                    </div>

                    <div className="step1 reverse1">
                        <div className="step-image">
                            <img src={gs1} alt="Get Results" />
                        </div>
                        <div className="step-content1">
                            <h2 style={{ textAlign: 'left' }}>Get Results</h2>
                            <p style={{ textAlign: 'left' }}>Following our expert tips and strategies with unit betting, the results are in your favor of becoming a profitable punter with our consistent betting advice.</p>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: 'center', padding: '20px', marginTop: '5%' }}>
                    <h1 style={{ fontSize: '32px', marginBottom: '20px' }} className='Results'>Photo Gallery</h1>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 1fr)', // 3 columns layout 
                            gridGap: '10px', // Spacing between images
                            justifyContent: 'center',
                            overflowX:'auto',
                        }}
                        className="results-grid"

                    >
                        {images.map((image, index) => (
                            <div key={index} style={{ width: '100%', height: 'auto' }}>
                                <img
                                    src={image}
                                    alt={`gallery-img-${index}`}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <div className="text-overlay" style={{
                    position: 'absolute',
                    marginLeft: '50px',
                    marginTop: '50px'


                }}>
                    <img src={image23} alt='image ' style={{ height: '100%', width: '100%' }} />
                </div>



                {/* <div className="get-started-section">
                    <div className="container">
                        <div className="content">
                            <h1 style={{ textAlign: 'left' }}>Get Started Today</h1>
                            <p style={{ textAlign: 'left' }}>Elevate your horse racing experience with the best tips and insights in the industry. Sign up now and start making smarter betting decisions.</p>
                            <div className="buttons">
                                <button className="sign-up">Sign Up Now</button>
                                <button className="latest-tips">View Latest Tips</button>
                            </div>
                        </div>
                        <img src={image24} alt="Whooska Logo" className="HorseRace" />
                    </div>

                    <div className="footer">
                        <img src={logo} alt="Whooska Logo" className="logo4" />

                        <div className="footer-links">
                            <a href="#">Home</a>
                            <div className="vertical-line"></div>
                            <a href="#">Tips</a>
                            <div className="vertical-line"></div>
                            <a href="">About Us</a>
                            <div className="vertical-line"></div>
                            <a href="#">Contact Us</a>
                            <div className="vertical-line"></div>
                            <a href="#">Privacy Policy</a>
                            <div className="vertical-line"></div>
                            <a href="#">Terms & Conditions</a>
                        </div>

                    </div>
                    <div className="footer-container1">
                        <div className="left-side">
                            <p style={{ color: '#FAD45C' }}>copyright @ whooska</p>
                        </div>
                        <p className="centered-paragraph">This page is for 18+ only. Gamble Responsibly. Visit <a href="#" style={{ color: '#FAD45C' }}>www.gamblinghelponline.org.vic.au</a> or call Gambling Help on <span style={{ color: '#FAD45C' }}>1800 858 858</span>. The chances are you are about to lose.</p>
                        <div className="right-side4">
                            <img src={image25} alt="Logo 1" className="log9" style={{ width: '40px', marginLeft: '10px' }} />
                            <img src={image26} alt="Logo 2" className="log9" style={{ width: '40px', marginLeft: '10px' }} />
                        </div>
                    </div>

                </div> */}
            </div>
        </>
    )
}

export default LandingPageComponents