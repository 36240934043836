import React, { useState } from 'react';
import logo from '../AssetsComponents/logoMain.png';
import '../StyleComponents/HeaderComponent.css';
import { Link } from 'react-router-dom';

function HeaderComponent() {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(prev => !prev);
    };

    return (
        <div className="header-containers">
            <header className="headers">
                <div className="logos">
                    <Link to="/LoginComponents">
                        <img src={logo} alt="Logos11" />
                    </Link>
                </div>
                <div className="nav-containers">
                    <nav className="navs">
                        <div className="hamburger" onClick={toggleMenu}>
                            &#9776; {/* Hamburger icon */}
                        </div>
                        <ul className={isMenuOpen ? 'open' : ''}>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/WhyWhooskaComponents">Why Whooska</Link></li>
                            <li><Link to="/WhooskaResultsComponents">Results</Link></li>
                            <li><Link to="/WhooskaFreeTipsComponents">Free Tips</Link></li>
                            <li><Link to="/WhooskaSubscription">Purchase Tips</Link></li>
                            <li><Link to="/ContactForm">Contact</Link></li>
                            {/* Authentication links in hamburger menu */}
                            <li><Link to="/LoginComponents" className='abs'>Log In</Link></li>
                            <li><Link to="/RegisterComponent" className='abs'>Sign Up</Link></li>
                        </ul>
                    </nav>
                </div>
            </header>
        </div>
    );
}

export default HeaderComponent;
