import React, { useState } from 'react';
import logo from '../AssetsComponents/logoMain.png'
import image18 from '../AssetsComponents/Vector.png'
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import horse from '../AssetsComponents/horseRacing.png'
import image25 from '../AssetsComponents/youtube.png'
import image26 from '../AssetsComponents/twitter.png'
import '../StyleComponents/WhooskaResultsComponents.css'
import { Link } from 'react-router-dom';
import HeaderComponent from '../ReusableComponent/HeaderComponent';







function WhooskaResultsComponents() {
  const data = [
    {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    },
    {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    },
    {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    },
    {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    }, {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    }, {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    }, {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    }, {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    }, {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    }, {
      date: '10 Aug 2024',
      title: 'Saratoga Power',
      race: '06',
      location: 'Muswellbrook',
      status: 'Win',
      odd: '$6',
      stake: '$3',
      collected: '+$310',
      image: image18, // Ensure this is properly imported or linked
    },
    // Add more data as needed
  ];

  const [selectedMonth, setSelectedMonth] = useState('August'); // Initial selected month

  const months = ['August', 'July', 'June'];

  return (
    <>
      <HeaderComponent />

      <div className="image-containers" style={{ position: 'relative', width: '100%', height: '500px' }}>
        <img
          src={horse}
          alt="Banner"
          style={{ height: '60%', width: '100%', objectFit: 'cover', filter: 'brightness(50%)' }}
        />
        <div
          className="text-overlays"
          style={{
            position: 'absolute',
            top: '30%',
            left: '67%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            fontSize: '34px',
            fontWeight: 'bold',
            zIndex: '99999',
            width: '40%'
          }}
        >
          <p>Results</p>
        </div>
      </div>
      <div className="results-section">
        <h1 className='results-title'>Results</h1>
        <div className="results-grid">
          {data.map((item, index) => (
            <div key={index} className="result-card">
              <div className="result-card-header">
                <p>{item.date}</p>
              </div>
              <div className="result-card-content">
                <h3>
                  <img
                    src={item.image}
                    alt={item.title}
                    className="result-card-image"
                  />
                  {item.title}
                </h3>
              </div>
              <div className="result-card-race">
                <p>Race {item.race}</p>
              </div>
              <hr className="divider" />
              <div className="result-card-location">
                <p>{item.location}</p>
              </div>
              <div className="result-card-status">
                <p>
                  {item.status}
                  <IoCheckmarkCircleOutline
                    className="status-icon"
                  />
                </p>
              </div>
              <hr className="divider" />
              <div className="result-card-footer">
                <p>Odd</p>
                <p>Stake</p>
                <p>Collected</p>
              </div>
              <div className="result-card-footer-values">
                <span>{item.odd}</span>
                <span>{item.stake}</span>
                <span className="collected-amount">{item.collected}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="get-started-sections">
        {/* <div className="container">
                    <div className="content">
                        <h1>Get Started Today</h1>
                        <p>Elevate your horse racing experience with the best tips and insights in the industry. Sign up now and start making smarter betting decisions.</p>
                        <div className="buttons">
                            <button className="sign-up">Sign Up Now</button>
                            <button className="latest-tips">View Latest Tips</button>
                        </div>
                    </div>
                    <img src={image24} alt="Whooska Logo" className="HorseRace" />
                </div> */}

        <div className="footers">
          <img src={logo} alt="Whooska Logo" className="logos4" />

          <div className="footer-link">
            <a href="#">Home</a>
            <div className="vertical-lines"></div>
            <a href="#">Tips</a>
            <div className="vertical-lines"></div>
            <a href="/WhyWhooskaComponents">About Us</a>
            <div className="vertical-lines"></div>
            <a href="#">Contact Us</a>
            <div className="vertical-lines"></div>
            <a href="#">Privacy Policy</a>
            <div className="vertical-lines"></div>
            <a href="#">Terms & Conditions</a>
          </div>

        </div>
        <div className="footer-containers1">
          <div className="left-sides">
            <p style={{ color: '#FAD45C' }}>copyright @ whooska</p>
          </div>
          <p className="centered-paragraphs">This page is for 18+ only. Gamble Responsibly. Visit <a href="#" style={{ color: '#FAD45C', textDecoration: 'none' }}>www.gamblinghelponline.org.vic.au</a> or call Gambling Help on <span style={{ color: '#FAD45C' }}>1800 858 858</span>. The chances are you are about to lose.</p>
          <div className="right-sides4">
            <img src={image25} alt="Logo 1" className="logs9" style={{ width: '40px', marginLeft: '10px' }} />
            <img src={image26} alt="Logo 2" className="logs9" style={{ width: '40px', marginLeft: '10px' }} />
          </div>
        </div>

      </div>
    </>

  )
}

export default WhooskaResultsComponents